<template>
  <div class="picked-serial--wrapper white rounded mb-5 px-3">
    <div class="d-flex align-center">
      <div class="counter d-flex align-center font-weight-bold mr-2">
        Đã chọn {{ pickedSerials.length }} / {{ maxSerialsQuantity }}
      </div>
      <div class="serial-list py-3 d-flex">
        <v-tooltip
          v-for="(item, index) in pickedSerials"
          bottom
          color="white"
          content-class="serial-tooltip elevation-4 px-2"
          :disabled="item.exchange_status === 0"
          :key="index"
          nudge-top="5px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-hover v-slot:default="{ hover }">
              <v-badge
                class="serial-list--item"
                color="error"
                icon="mdi-trash-can-outline"
                overlap
                offset-x="20px"
                offset-y="15px"
                :value="hover"
              >
                <v-icon
                  slot="badge"
                  @click="removePickedSerial(item.serial_number)"
                >
                  mdi-trash-can-outline
                </v-icon>
                <v-chip
                  class="tp-btn--text grey lighten-4 px-2 mr-2"
                  label
                  v-on="on"
                  v-bind="attrs"
                >
                  <span class="pr-2">
                    {{ item.serial_number }}
                  </span>
                </v-chip>
              </v-badge>
            </v-hover>
          </template>
          <div
            v-if="item.exchange_status === 1"
            class="black--text text-body-2 text-center"
            style="width: 180px"
          >
            Đang được chuyển đến
            {{ item.exchange_goods.branch_nhap_name }} trong phiếu chuyển
            {{ item.exchange_goods.code }}
          </div>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    maxSerialsQuantity: {
      required: true
    }
  },

  computed: {
    pickedSerialNumbers() {
      return this.$store.getters["MODAL_SERIAL_PICKER/pickedSerialNumbers"];
    },
    pickedSerials() {
      return this.$store.getters["MODAL_SERIAL_PICKER/pickedSerials"];
    }
  },
  methods: {
    removePickedSerial(serialNumber) {
      const indexOfSerial = this.pickedSerialNumbers.indexOf(serialNumber);

      // Remove serial
      this.pickedSerials.splice(indexOfSerial, 1);
      // Update picked serials state
      this.$store.dispatch(
        "MODAL_SERIAL_PICKER/setPickedSerials",
        this.pickedSerials
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.picked-serial--wrapper {
  min-height: 56px;
  .counter {
    min-height: 56px;
  }
}
.serial-list {
  max-width: calc(100% - 90px);
  overflow-x: auto;
}
</style>
