<template>
  <div class="d-flex align-center">
    <!-- Start: Branch filter -->
    <div class="d-flex">
      <div
        class="d-flex align-center white tp-btn--text rounded-lg px-3 py-2 mr-3"
        :class="{ 'cyan lighten-5': selectedBranchFilter.type === 1 }"
        @click="selectBranchFilter(1, currentBranchId)"
      >
        <div class="mr-1">
          <div>
            <v-icon
              :color="
                selectedBranchFilter.type === 1 ? 'primary' : 'grey lighten-1'
              "
              size="20px"
              style="margin-bottom: 1px"
            >
              mdi-account
            </v-icon>
            {{ currentBranch.name }}
          </div>
        </div>
        <v-chip
          class="font-weight-bold justify-center rounded ml-1 px-1"
          color="primary"
          dark
          style="height: 20px; min-width: 18px"
          small
        >
          {{ currentBranchSerialsCount }}
        </v-chip>
      </div>

      <div
        class="d-flex align-center white tp-btn--text rounded-lg px-3 py-2 mr-3"
        :class="{ 'cyan lighten-5': selectedBranchFilter.type === 2 }"
        @click="selectBranchFilter(2, currentBranch.tinh_thanhpho)"
      >
        <div class="mr-1">
          Trong khu vực
        </div>
        <v-chip
          class="font-weight-bold justify-center rounded ml-1 px-1"
          color="primary"
          dark
          style="height: 20px; min-width: 18px"
          small
        >
          {{ currentBranchAreaSerialsCount }}
        </v-chip>
      </div>

      <div style="max-width: 220px">
        <v-select
          class="text-body-1 rounded-lg mt-0"
          :background-color="
            selectedBranchFilter.type === 3 ? 'cyan lighten-5' : null
          "
          dense
          flat
          hide-details
          :items="branchSerialsListSelectOptions"
          item-text="branch_name"
          item-value="branch_id"
          :menu-props="{ maxWidth: '332px', nudgeTop: '-40px' }"
          no-data-text="Không có dữ liệu"
          placeholder="Chọn chi nhánh"
          solo
          @change="selectBranchFilter(3, $event)"
        >
          <template v-slot:selection="data">
            <div class="py-2">
              <span class="black--text">
                {{ data.item.branch_name }}
              </span>
              <v-chip
                class="font-weight-bold justify-center rounded ml-1 px-1"
                color="primary"
                dark
                style="height: 20px; min-width: 18px"
                small
              >
                {{
                  data.item.branch_id === "all"
                    ? data.item.quantity
                    : data.item.serials.length
                }}
              </v-chip>
            </div>
          </template>
          <template v-slot:item="data">
            <div class="py-2">
              <span>
                {{ data.item.branch_name }}
              </span>
              <v-chip
                class="font-weight-bold justify-center rounded ml-1 px-1"
                color="primary"
                dark
                style="height: 20px; min-width: 18px"
                small
              >
                {{
                  data.item.branch_id === "all"
                    ? data.item.quantity
                    : data.item.serials.length
                }}
              </v-chip>
            </div>
          </template>
        </v-select>
      </div>
    </div>
    <!-- End: Branch filter -->

    <v-spacer></v-spacer>

    <!-- Start: Search area -->
    <div v-if="false">
      <v-text-field
        v-model="searchKey"
        class="rounded-lg text-body-1"
        clearable
        dense
        flat
        hide-details
        placeholder="Tìm serial"
        prepend-inner-icon="mdi-magnify"
        solo
      ></v-text-field>
    </div>
    <!-- End: Search area -->
  </div>
</template>

<script>
export default {
  props: {
    selectedBranchFilter: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      searchKey: null
    };
  },
  computed: {
    currentBranch() {
      return this.branches.find(item => item.id === this.currentBranchId);
    },
    currentBranchId() {
      return this.$store.getters["MODAL_SERIAL_PICKER/branch"];
    },
    currentBranchAreaSerialsCount() {
      const currentBranchAreaSerials = this.branchSerialsList.filter(
        item => item.branch_tinh_thanhpho === this.currentBranch.tinh_thanhpho
      );

      return currentBranchAreaSerials.reduce(
        (counter, item) => counter + item.serials.length,
        0
      );
    },
    currentBranchSerialsCount() {
      const currentBranchSerials = this.branchSerialsList.find(
        item => item.branch_id === this.currentBranchId
      );

      if (currentBranchSerials) {
        return currentBranchSerials.serials.length;
      } else {
        return 0;
      }
    },
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },
    branchSerialsList() {
      return this.$store.getters["MODAL_SERIAL_PICKER/branchSerialsList"];
    },
    branchSerialsListSelectOptions() {
      let arr = [...this.branchSerialsList];
      const item = {
        branch_id: "all",
        branch_name: "Toàn hệ thống",
        quantity: 0
      };

      item.quantity = this.branchSerialsList.reduce((counter, item) => {
        if (item.serials) {
          return counter + item.serials.length;
        }
      }, 0);

      arr.unshift(item);

      return [item, ...arr];
    },
    filteredBranchSerialsList() {
      return this.$store.getters[
        "MODAL_SERIAL_PICKER/filteredBranchSerialsList"
      ];
    }
  },
  created() {
    this.$store.dispatch("BRANCH/getAllBranches");
  },
  methods: {
    selectBranchFilter(type, branchId) {
      const branchSerialsList = JSON.parse(
        JSON.stringify(this.branchSerialsList)
      );
      let arr = [];

      this.$emit("updateSelectedBranchFilter", {
        type: type,
        value: branchId
      });

      if (type === 1) {
        arr = branchSerialsList.filter(item => item.branch_id === branchId);
      }
      if (type === 2) {
        arr = branchSerialsList.filter(
          item => item.branch_tinh_thanhpho === branchId
        );
      }
      if (type === 3) {
        if (branchId === "all") {
          arr = branchSerialsList;
        } else {
          arr = branchSerialsList.filter(item => item.branch_id === branchId);
        }
      }

      // Update filteredBranchSerial
      this.$store.dispatch(
        "MODAL_SERIAL_PICKER/setFilteredBranchSerialsList",
        arr
      );
    }
  }
};
</script>
