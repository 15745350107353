<template>
  <tp-modal
    v-if="productOption && productOption.id"
    name="modal-serial-picker"
    max-width="1024px"
    width="90%"
  >
    <v-card slot-scope="props">
      <div class="d-flex justify-space-between px-6 py-4">
        <div class="mr-5">
          <div class="d-flex align-center text-h6 font-weight-bold">
            {{ productOption.product_name }}
          </div>
          <div>
            <span class="font-weight-medium">
              <span>SKU: {{ productOption.SKU }}</span>
              <span v-if="productOption.MPN">
                - MPN: {{ productOption.MPN }}</span
              >
            </span>
            <span class="mx-2">|</span>
            <span class="tag-p__mb-0" v-html="productOption.name"></span>
          </div>
        </div>
        <div class="d-flex align-center">
          <v-btn
            class="cyan lighten-5 rounded-lg mr-2"
            color="primary"
            text
            @click="onCompleteHandle(props.payload.onComplete)"
          >
            Xong
          </v-btn>

          <v-btn icon @click="closeModal()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>

      <v-container class="grey lighten-3 pa-5" fluid>
        <picked-serial
          :max-serials-quantity="props.payload.maxSerialsQuantity"
        ></picked-serial>

        <!-- Start: Top Action -->
        <top-action
          :selected-branch-filter="selectedBranchFilter"
          @updateSelectedBranchFilter="selectedBranchFilter = $event"
        ></top-action>
        <!-- End: Top Action -->

        <div class="d-flex mt-5">
          <!-- Start: Main sidebar-->
          <main-sidebar
            :selected-branch-filter="selectedBranchFilter"
            :selected-serial-status="selectedSerialStatus"
            @updateSelectedSerialStatus="selectedSerialStatus = $event"
          ></main-sidebar>
          <!-- End: Main sidebar-->

          <!-- Start: Main content-->
          <main-content
            class="main-content pl-4"
            :max-serials-quantity="props.payload.maxSerialsQuantity"
            :selected-branch-filter="selectedBranchFilter"
            :selected-serial-status="selectedSerialStatus"
          ></main-content>
          <!-- Start: Main content-->
        </div>
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
import MainContent from "./components/MainContent";
import MainSidebar from "./components/MainSidebar";
import PickedSerial from "./components/PickedSerial";
import TopAction from "./components/TopAction";

export default {
  components: {
    MainContent,
    MainSidebar,
    PickedSerial,
    TopAction
  },
  data() {
    return {
      selectedBranchFilter: {
        type: 1,
        branchId: null
      },
      selectedSerialStatus: "all"
    };
  },
  computed: {
    pickedSerials() {
      return this.$store.getters["MODAL_SERIAL_PICKER/pickedSerials"];
    },
    productOption() {
      return this.$store.getters["MODAL_SERIAL_PICKER/productOption"];
    },
    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  },
  methods: {
    closeModal() {
      this.selectedBranchFilter = {
        type: 1,
        branchId: null
      };
      this.$modal.hide({ name: "modal-serial-picker" });
    },

    onCompleteHandle(onComplete) {
      if (typeof onComplete === "function") {
        onComplete(this.pickedSerials);
      }

      this.closeModal();
    }
  }

  // watch: {
  //   clonePickedSerials: {
  //     handler(val, oldVal) {
  //       if (val.length > 2) {
  //         this.$nextTick(() => {
  //           this.$store.dispatch(
  //             "MODAL_SERIAL_PICKER/setPickedSerials",
  //             oldVal
  //           );
  //         });
  //       }
  //     }
  //   },
  //   deep: true
  // }
};
</script>

<style lang="scss" scoped>
.main-content {
  flex: 1;
}
</style>
