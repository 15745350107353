<template>
  <div>
    <template v-if="filteredBranchSerialsList.length > 0">
      <card-branch-serials
        v-for="(item, index) in filteredBranchSerialsList"
        :branch-serials="item"
        :class="{ 'mt-3': index !== 0 }"
        :key="index"
        :max-serials-quantity="maxSerialsQuantity"
        :selected-branch-filter="selectedBranchFilter"
        :selected-serial-status="selectedSerialStatus"
      ></card-branch-serials>
    </template>
    <template v-else>
      <v-card class="grey lighten-5" flat>
        <v-card-title class="justify-center">Không có serial nào</v-card-title>
      </v-card>
    </template>
  </div>
</template>

<script>
import CardBranchSerials from "./CardBranchSerials";

export default {
  props: {
    maxSerialsQuantity: {
      required: true
    },
    selectedBranchFilter: {
      type: Object,
      required: true
    },
    selectedSerialStatus: {
      type: [Number, String],
      required: true
    }
  },
  components: {
    CardBranchSerials
  },
  computed: {
    filteredBranchSerialsList() {
      return this.$store.getters[
        "MODAL_SERIAL_PICKER/filteredBranchSerialsList"
      ];
    }
  }
};
</script>
