<template>
  <v-card class="white pb-4" flat>
    <v-card-title class="text-body-1 font-weight-bold pb-0">
      <v-btn class="mr-1" icon small @click="visible = !visible">
        <v-icon>
          {{ visible ? "mdi-menu-down" : "mdi-menu-right" }}
        </v-icon>
      </v-btn>
      {{ branchSerials.branch_name }} ({{ filteredSerialByStatus.length }})
      <v-spacer></v-spacer>
      <!--<div class="font-weight-light font-italic ml-1">(Đã chọn 1)</div>-->
    </v-card-title>

    <v-row v-if="visible" class="px-4 mt-1">
      <!-- Start: Serials list -->
      <v-col class="py-0" cols="9">
        <div class="scrollable" v-if="filteredSerialByStatus.length > 0">
          <v-tooltip
            v-for="(item, index) in filteredSerialByStatus"
            bottom
            color="white"
            content-class="elevation-4 px-2"
            :disabled="item.exchange_status === 0 && item.order_status === 0"
            :key="index"
            nudge-top="5px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot:default="{ hover }">
                <v-chip
                  class="px-2 mt-2 mr-2"
                  :class="
                    (hover &&
                      item.order_status !== 1 &&
                      item.status === 1 &&
                      pickedSerials.length < maxSerialsQuantity) ||
                    pickedSerialNumbers.includes(item.serial_number)
                      ? 'tp-btn--text primary'
                      : item.order_status === 1 || item.status === -13
                      ? 'grey lighten-4 grey--text'
                      : pickedSerials.length >= maxSerialsQuantity
                      ? 'grey lighten-4 grey--text'
                      : 'tp-btn--text grey lighten-4'
                  "
                  label
                  v-on="on"
                  v-bind="attrs"
                  @click="
                    pickedSerialNumbers.includes(item.serial_number)
                      ? removePickedSerial(item.serial_number)
                      : pickedSerials.length < maxSerialsQuantity &&
                        item.order_status === 0 &&
                        item.status === 1
                      ? pickSerial(item)
                      : null
                  "
                >
                  <span class="pr-2">
                    {{ item.serial_number }}
                  </span>
                </v-chip>
              </v-hover>
            </template>
            <div
              v-if="item.exchange_status === 1"
              class="black--text text-body-2 text-center"
              style="width: 180px"
            >
              Đang được chuyển đến
              {{ item.exchange_goods.branch_nhap_name }} trong phiếu chuyển
              {{ item.exchange_goods.code }}
            </div>
            <div
              v-if="item.order_status === 1"
              class="black--text text-body-2 text-center"
              style="width: 180px"
            >
              Đang được gán trong đơn xử lý
              {{ item.order.order_handle_code }} của đơn hàng
              {{ item.order.order_code }}
            </div>
          </v-tooltip>
        </div>
        <v-card class="grey lighten-4" flat v-else>
          <v-card-title class="text-body-1 justify-center"
            >Không có serials nào.</v-card-title
          >
        </v-card>
      </v-col>
      <!-- End: Serials list -->
      <!-- Start: Stock info -->
      <v-col class="py-0" cols="3">
        <div class="scrollable yellow lighten-5 fill-height rounded px-3 py-2">
          <div class="font-weight-bold">
            Khách đặt hàng: {{ branchSerials.stock_info.ordersCount }}
          </div>
          <div class="mt-1">
            <div class="font-weight-bold">
              Đang về: {{ branchSerials.stock_info.pos.length }}
            </div>
            <ul class="ml-0">
              <li
                class="text-body-2 py-1"
                v-for="(item, index) in branchSerials.stock_info.pos"
                :key="index"
              >
                {{ item.qty }}
                <router-link
                  class="font-weight-bold primary--text text-decoration-none"
                  target="_blank"
                  :to="{
                    name: 'transaction_goods-receipts_purchase-orders-detail',
                    params: {
                      purchaseOrderId: item.id
                    }
                  }"
                  >{{ item.code }}</router-link
                >
                dự kiến về {{ item.est_date | formatDate }}
                {{
                  getTimeLeft(item.est_date) ? getTimeLeft(item.est_date) : null
                }}
              </li>
            </ul>
          </div>
        </div>
      </v-col>
      <!-- End: Stock info -->
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    maxSerialsQuantity: {
      required: true
    },
    branchSerials: {
      type: Object,
      required: true
    },
    selectedBranchFilter: {
      type: Object,
      required: true
    },
    selectedSerialStatus: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      visible: true
    };
  },
  computed: {
    filteredSerialByStatus() {
      if (
        this.selectedSerialStatus !== "exchange_status" &&
        this.selectedSerialStatus !== "all"
      ) {
        return this.branchSerials.serials.filter(
          item => item.status === this.selectedSerialStatus
        );
      }
      if (this.selectedSerialStatus === "exchange_status") {
        return this.branchSerials.serials.filter(
          item => parseInt(item.exchange_status) === 1
        );
      }
      return this.branchSerials.serials;
    },
    pickedSerialNumbers() {
      return this.$store.getters["MODAL_SERIAL_PICKER/pickedSerialNumbers"];
    },
    pickedSerials() {
      return this.$store.getters["MODAL_SERIAL_PICKER/pickedSerials"];
    }
  },
  filters: {
    formatDate(date) {
      if (!date) return null;

      const dateTime = new Date(date),
        day = dateTime
          .getDate()
          .toString()
          .padStart(2, "0"),
        month = (dateTime.getMonth() + 1).toString().padStart(2, "0"),
        year = dateTime.getFullYear();

      return `${day}/${month}/${year}`;
    }
  },
  methods: {
    checkExistedOldSerial(serial, branchSerials) {
      return branchSerials
        .filter(item => !this.pickedSerialNumbers.includes(item.serial_number))
        .some(item => {
          return (
            new Date(item.import_date) < new Date(serial.import_date) &&
            item.status === 1 &&
            item.order_status === 0
          );
        });
    },

    getTimeLeft(d) {
      const date = new Date(d),
        now = new Date(),
        diffTime = date - now;

      if (diffTime > 0) {
        return `(Còn ${Math.ceil(diffTime / (1000 * 60 * 60 * 24))} ngày)`;
      } else {
        return null;
      }
    },

    pickSerial(serial) {
      if (this.checkExistedOldSerial(serial, this.branchSerials.serials)) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `Bạn nên chọn serial tồn kho lâu hơn <span class="font-weight-bold red--text text--accent-2 mr-1">${serial.serial_number}</span> để xuất bán`,
            message:
              "Các serial được sắp xếp theo thứ tự nhập từ lâu đến mới nhất theo từng chi nhánh. " +
              "Xuất bán serial cũ hơn nhằm giảm số lượng tồn kho, ảnh hưởng trực tiếp đến KPI bán hàng của ngành hàng. " +
              "Bạn vẫn có thể chọn serial hiện tại nếu bấm tiếp tục. Hãy cân nhắc nhé!",
            confirmBtn: {
              color: "red accent-2",
              text: "Tiếp tục",
              onClickHandler: () => {
                this.pickedSerials.unshift(serial);
              }
            }
          }
        });
      } else {
        this.pickedSerials.unshift(serial);
      }

      // Update picked serials state
      this.$store.dispatch(
        "MODAL_SERIAL_PICKER/setPickedSerials",
        this.pickedSerials
      );
    },

    removePickedSerial(serialNumber) {
      const indexOfSerial = this.pickedSerialNumbers.indexOf(serialNumber);

      // Remove serial
      this.pickedSerials.splice(indexOfSerial, 1);
      // Update picked serials state
      this.$store.dispatch(
        "MODAL_SERIAL_PICKER/setPickedSerials",
        this.pickedSerials
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.scrollable {
  height: 100%;
  max-height: 180px;
  overflow-y: auto;
}
</style>
